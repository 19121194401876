@tailwind base;
@tailwind components;
@tailwind utilities;
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ace_editor {
    width: 100% !important;
}

.sc-bcCSHH,
.dwRLlu {
    /* display: none !important; */
    /* position: absolute; */
}

.sc-lcDUFh,
.jhROzp {
    height: 100%;
}

.sc-fpYaaq,
.ieGZQL {
    height: 100%;
}

.ace_editor p,
.ace_editor div {
    font-family: 'Roboto Mono', monospace!important;
    letter-spacing: 0;
}